import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout/Layout'
import * as styles from '../styles/Product.module.scss'
import ProductHero from '../components/ProductHero/ProductHero'
import ProductAccordion from '../components/ProductAccordion/ProductAccordion'
import ProductAddInfo from '../components/ProductAddInfo/ProductAddInfo'
import ProductCTA from '../components/ProductCTA/ProductCTA'
import Seo from '../components/Seo/Seo'
import ProductMediaBlock from '../components/ProductMediaBlock/ProductMediaBlock'
import ProductMultiSection from '../components/ProductMultiSection/ProductMultiSection'
import ProductContent from '../components/ProductContent/ProductContent'
// import pincailogo from '../assets/images/pincai-text-logo-black.svg'

export const query = graphql`
query product {
  allSanityProductpage {
    nodes {
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      slug {
        current
      }
      hero {
        _type
        solutionCategory
        marketoFormID
        solutionName
        solutionDescription
        heroButtonName
        heroImage {
          asset {
            url
          }
        }
      }
      mediaBlock {
        _type
        title
        _rawDescription
        video
        showElement
        image {
          asset {
            url
          }
        }
      }
      multiSection {
        _type
        showElement
        clinIntTitle
        _rawClinIntSection
        valueTitle
        _rawValueSection
        margImprovTitle
        _rawMargImprovSection
      }
      accordion {
        _type
        headerImage {
          asset {
            url
          }
        }
        headerVideo
        sectionTitle
        _rawSectionDescription
        accordionItems {
          itemTitle
          alignment
          _rawItemDescription
        }
      }
      content {
        showElement
        logo {
          asset {
            url
          }
        }
        altLogoText
        blocks {
          ... on SanityTeamList {
            _type
            showElement
            title
            _rawDescription
            team
          }
          ... on SanityYellowBlock {
            _type
            blockContext
            showElement
            _rawBoldText
            _rawTextContent
          }
          ... on SanityBillboard {
            _type
            title
            showElement
            maxColumns
            _rawFootnote
            _rawDescription
            backgroundImage {
              asset {
                url
              }
            }
            altText
            posts {
              ... on SanityWhitePaper {
                title
                showElement
                _rawDescription
                button {
                  buttonText
                  buttonData
                  buttonContext
                  buttonFile {
                    asset {
                      url
                    }
                  }
                }
              }
              ... on SanityResource {
                title
                showElement
                _rawDescription
                button {
                  buttonText
                  buttonData
                  buttonContext
                  buttonFile {
                    asset {
                      url
                    }
                  }
                }
              }
              ... on SanityPost {
                _type
                title
                showElement
                lineBreak
                icon {
                  asset {
                    url
                  }
                }
                _rawDescription
                button {
                  buttonText
                  buttonData
                  buttonContext
                  buttonFile {
                    asset {
                      url
                    }
                  }
                }
                altText
              }
            }
            billboardType
          }
          ... on SanityFeatureList {
            _type
            title
            showElement
            maxColumns
            features {
              title
              showElement
              footnoteText
              _rawFootnote
              _rawDescription
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                  }
                }
              }
            }
          }
          ... on SanityItemPopoverSection {
            _type
            showElement
            title
            _rawDescription
            items {
              title
              subtitle
              showElement
              _rawDescription
              altText
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
      addInfo {
        _type
        showElement
        headerImage {
          asset {
            url
            gatsbyImageData
          }
        }
        infoArr {
          showElement
          title
          description
          ctaTitle
          ctaRoute
          ctaFile {
            asset {
              url
            }
          }
        }
      }
      cta {
        _type
        title
        description
        buttonName {
          showArrow
          buttonText
          buttonFile {
            asset {
              url
            }
          }
          buttonData
          buttonContext
        }
      }
    }
  }
  allSanitySolutionpage {
    nodes {
      id
      settings {
        primaryColor
        secondaryColor
      }
      slug {
        current
      }
      heroSection {
        icon {
          asset {
            url
          }
        }
      }
      solutionTitle
    }
  }
  allSanityTeamMember(sort: {fields: orderRank}) {
    nodes {
      showElement
      name
      orderRank
      profile {
        asset {
          url
        }
      }
      altText
      associatedTeams
      position
      degrees
      _rawBiography
      showcase {
        caseName
        _rawDescription
      }
    }
  }
}
`

//const 

const ProductsTemplate = props => {
    const {data, pageContext} = props;
    // pageContext provided by createPage in gatsby-node.js
    const {productSlug} = pageContext;

    const pageData = data.allSanityProductpage.nodes.find(product => 
      // product.slug.current === window.location.href.split("products/")[1]);
      product.slug.current === productSlug.current);

    const {hero, mediaBlock, multiSection, accordion, content, addInfo, cta} = pageData;
    const solutionInfo = data.allSanitySolutionpage.nodes;

    let currentProduct = '';
    currentProduct = solutionInfo.find(solution => 
        solution.solutionTitle === hero.solutionCategory
    );

    if(content) {
      content.teamMembers = data.allSanityTeamMember.nodes;
    }

    // const pageTitle = slug.current === 'enterprise-resource-planning' ? 'Enterprise Resource Planning' : 'PINC AI';
    // const pageTitle = title;
    return (
        <Layout background="light">
            <div className={styles.productPageWrap}>

                <header className={styles.headerContainer}>
                  {/* <h1 className={styles.header}>
                    <span><img src={pincailogo} alt="Pinc AI Logo" /></span>
                    <span>Solutions</span>
                  </h1> */}
                </header>

                {/* <div className={styles.spacer}></div> */}

                {hero.heroImage !== null &&
                  <ProductHero data={hero} theme={currentProduct} />
                }

                {mediaBlock !== null && mediaBlock.showElement &&
                  <ProductMediaBlock data={mediaBlock} theme={currentProduct} />
                }

                {multiSection !== null && multiSection.showElement &&
                  <ProductMultiSection data={multiSection} theme={currentProduct} allThemes={solutionInfo} />
                }

                {accordion !== null && 
                  <ProductAccordion data={accordion} theme={currentProduct} />
                }

                {content !== null && content.showElement &&
                  <ProductContent data={content} theme={currentProduct} />
                }

                {addInfo !== null && addInfo.showElement &&
                  <ProductAddInfo data={addInfo} theme={currentProduct} />
                }

                {cta !== null &&
                  <ProductCTA data={{...cta, solutionCategory: hero.solutionCategory}} theme={currentProduct} />
                }
            </div>
        </Layout>
    )
}

export default ProductsTemplate

export const Head = (props) => {
  const { data, pageContext } = props;
  const {productSlug} = pageContext;

  const pageData = data.allSanityProductpage.nodes.find(product => 
    // product.slug.current === window.location.href.split("products/")[1]);
    product.slug.current === productSlug.current);
  return (
    <Seo title={pageData.browserTitle} seo={pageData.seoData} />
  )
}